/*@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}*/

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Medium.woff') format('woff');
  font-style: normal;
  font-weight: 600; /* SemiBold */
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}

* {
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif !important;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Gilroy', sans-serif !important;
  background-color: #fafbfb !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiIconButton-root {
border-radius: 5px !important;
}

@import './video.css';
@import './loadingBar.css';


/* Maintenance */

.container-maintenance {
margin: 0;
padding: 1rem;
display: flex;
justify-content: center;
height: 100vh;
background-color: #ffffff;
background:
    conic-gradient(#ffffff 25%,#0000 0) 0 0 / calc(2*12px) calc(12px / 9.5),
    conic-gradient(#ffffff 25%,#0000 0) 0 0 / calc(12px / 9.5) calc(2 * 12px),
    repeating-conic-gradient(#0000 0 25%,#ffffff 0 50%) 
     12px 0 /calc(2*12px) calc(2*12px),
     radial-gradient(circle at 50% 50%, #0da893 98%, #ffffff) 
     0 0/12px 12px;
}

.grid-container {
display: grid;
grid-template-columns: 1fr 1fr;
gap: 1rem;
padding: 1rem;
padding-inline: 5rem;
background-color: #ffffff;
border: 1px solid #ffffff;
border-radius: 1rem;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.grid-item {
/* background-color: #007BFF; */
color: #000000;
text-align: left;
border-radius: 4px;
display: flex;
flex-direction: column;
justify-content: center;
}

.grid-item-image{
margin-inline: auto;
width: 100%;
display: flex;
justify-content: center;
}


.title-maintenance{
font-size: 1.5rem;
color: #0da893;
text-align: center;
margin-bottom: 2rem;
}
.subtitle-maintenance{
font-size: 1rem;
font-weight: normal;

}

.button-secondary-maintenance{
background-color: #0da893 !important;
color: #fafbfb !important;
width: 50%;
height: 50px;
padding: 0 40px;
font-size: 1rem !important;
font-weight: bold !important;
border-radius: 5px;
box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 1px;
color:#ffffff;
width: 50%;
margin-top: 2rem !important;
}

.button-secondary-maintenance:hover{
background: #fafbfb !important;
color: #0da893 !important;
border: 1px solid #0da893;
}

.form-maintenance{
display: flex;
flex-direction: column;
gap: 1rem;
margin-top: 2rem;
}

.logo-maintenance{
width: 100%;
}

.hide-section-desktop{
display: none;
}


/* Smartphones (portrait & landscape) 
#Dispositivo = Mayoría de teléfonos móviles (vertical y horizontal) 
#Resolución = B/w 320px to 479px */
@media only screen and (min-device-width : 1px) and (max-width: 800px){
.button-secondary-maintenance{
  width: 100%;
  margin-top: 0% !important;
}
.grid-container{
  padding-inline:10px;
  grid-template-columns:1fr;
}
.hide-section{
  display: none;
}
.hide-section-desktop{
  display: flex;
}

.title-maintenance{
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 0rem;
}

  .container-maintenance{
    height: auto;
  }
}


.rotateIconSearch {
  animation: rotate 2s linear infinite; /* Animation for rotation */
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
}
}